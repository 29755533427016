<template>
  <div class="con-wrap">
    <CarrotTitle title="재직자 관리">
      <div class="float-right">
        <div class="btn-ibbox title-btnbox dp-inblock mr-10">
          <router-link to="RetireeManagementList" target="_blank">
            <button v-if="hq.isAuth" class="btn-default">퇴직자 관리</button>
          </router-link>
        </div>
        <div class="btn-ibbox title-btnbox dp-inblock">
          <router-link to="IMOverallstatisticsList" target="_blank"
            ><button class="btn-default">전체 통계</button></router-link
          >
        </div>
      </div>
      - 재직자 등록/관리 페이지 입니다. 이름 클릭 시 인사카드로 이동됩니다.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row table-serach">
            <tbody>
              <tr>
                <th width="180">입사일</th>
                <td>
                  <CarrotDatePicker
                    v-model="hq.sdate"
                    class="w-110px float-left"
                  ></CarrotDatePicker>
                  <span class="float-left ml-10 mr-10">~</span>
                  <CarrotDatePicker
                    v-model="hq.edate"
                    class="w-110px float-left"
                  ></CarrotDatePicker>
                </td>
                <th width="180">연락처</th>
                <td>
                  <input type="text" class="w-200px" v-model="hq.phone" />
                </td>
              </tr>
              <tr>
                <th width="180">이름</th>
                <td>
                  <input type="text" class="w-200px" v-model="hq.hqname" />
                </td>
                <th width="180">상태</th>
                <td>
                  <select class="w-200px" v-model="hq.state">
                    <option value="DUTY">재직자</option>
                    <option value="LEAVE">휴직자</option>
                    <option value="QUIT">퇴사자</option>
                    <option value="BABYLEAVE">육아휴직자</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn-default float-right mt-10" @click="hq.doSearch">
            검색
          </button>
          <div class="clear"></div>

          <div class="dp-table w-100per mt-50">
            <div class="w-100per">
              <table class="table-col">
                <thead>
                  <tr>
                    <th v-for="(irow, i) in hq.analysis.sectorList" :key="i">
                      {{ irow.deptname }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-for="(irow, i) in hq.analysis.sectorList" :key="i">
                      {{ irow.cnt }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="mt-40 mb-20">
            <CarrotSector
              v-model="hq.idx_sector"
              @change="hq.doSearchOffice"
              class="w-200px"
            ></CarrotSector>
            <CarrotDept
              v-model:idx_sector.sync="hq.idx_sector"
              v-model="hq.idx_office"
              @change="hq.doSearchTeam"
              class="w-200px"
            ></CarrotDept>
            <CarrotTeam
              :idx_sector="hq.idx_sector"
              :idx_office="hq.idx_office"
              v-model="hq.idx_team"
              @change="hq.doSearch"
              class="w-200px ml-5"
            ></CarrotTeam>
            <router-link to="EmployeeAdd"
              ><button class="btn-default float-right ml-10">
                직원 등록
              </button></router-link
            >
            <button class="btn-default float-right" @click="hq.downExcel">
              Excel 다운로드
            </button>
            <div class="clear"></div>
          </div>

          <table class="table-col table-auto">
            <!-- <colgroup>
                        </colgroup> -->
            <thead>
              <tr>
                <th width="70">No</th>
                <th>
                  이름
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby != 'nameasc' && hq.orderby != 'namedesc'"
                    @click="hq.doSearchOrderBy('nameasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby != 'nameasc' && hq.orderby != 'namedesc'"
                    @click="hq.doSearchOrderBy('nameasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'nameasc'"
                    @click="hq.doSearchOrderBy('namedesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'namedesc'"
                    @click="hq.doSearchOrderBy('nameasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>
                  직급
                  <span
                    style="cursor: pointer"
                    v-if="
                      hq.orderby != 'positionasc' &&
                      hq.orderby != 'positiondesc'
                    "
                    @click="hq.doSearchOrderBy('positionasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="
                      hq.orderby != 'positionasc' &&
                      hq.orderby != 'positiondesc'
                    "
                    @click="hq.doSearchOrderBy('positionasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'positionasc'"
                    @click="hq.doSearchOrderBy('positiondesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'positiondesc'"
                    @click="hq.doSearchOrderBy('positionasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>
                  팀
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby != 'teamasc' && hq.orderby != 'teamdesc'"
                    @click="hq.doSearchOrderBy('teamasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby != 'teamasc' && hq.orderby != 'teamdesc'"
                    @click="hq.doSearchOrderBy('teamasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'teamasc'"
                    @click="hq.doSearchOrderBy('teamdesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'teamdesc'"
                    @click="hq.doSearchOrderBy('teamasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>
                  본부
                  <span
                    style="cursor: pointer"
                    v-if="
                      hq.orderby != 'officeasc' && hq.orderby != 'officedesc'
                    "
                    @click="hq.doSearchOrderBy('officeasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="
                      hq.orderby != 'officeasc' && hq.orderby != 'officedesc'
                    "
                    @click="hq.doSearchOrderBy('officeasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'officeasc'"
                    @click="hq.doSearchOrderBy('officedesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'officedesc'"
                    @click="hq.doSearchOrderBy('officeasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>
                  부문
                  <span
                    style="cursor: pointer"
                    v-if="
                      hq.orderby != 'sectorasc' && hq.orderby != 'sectordesc'
                    "
                    @click="hq.doSearchOrderBy('sectorasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="
                      hq.orderby != 'sectorasc' && hq.orderby != 'sectordesc'
                    "
                    @click="hq.doSearchOrderBy('sectorasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'sectorasc'"
                    @click="hq.doSearchOrderBy('sectordesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'sectordesc'"
                    @click="hq.doSearchOrderBy('sectorasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>
                  소속법인
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby != 'corpasc' && hq.orderby != 'corpdesc'"
                    @click="hq.doSearchOrderBy('corpasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby != 'corpasc' && hq.orderby != 'corpdesc'"
                    @click="hq.doSearchOrderBy('corpasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'corpasc'"
                    @click="hq.doSearchOrderBy('corpdesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'corpdesc'"
                    @click="hq.doSearchOrderBy('corpasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>
                  생년월일
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby != 'birthasc' && hq.orderby != 'birthdesc'"
                    @click="hq.doSearchOrderBy('birthasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby != 'birthasc' && hq.orderby != 'birthdesc'"
                    @click="hq.doSearchOrderBy('birthasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'birthasc'"
                    @click="hq.doSearchOrderBy('birthdesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'birthdesc'"
                    @click="hq.doSearchOrderBy('birthasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>
                  입사일
                  <span
                    style="cursor: pointer"
                    v-if="
                      hq.orderby != 'joindayasc' && hq.orderby != 'joindaydesc'
                    "
                    @click="hq.doSearchOrderBy('joindayasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="
                      hq.orderby != 'joindayasc' && hq.orderby != 'joindaydesc'
                    "
                    @click="hq.doSearchOrderBy('joindayasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'joindayasc'"
                    @click="hq.doSearchOrderBy('joindaydesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="hq.orderby == 'joindaydesc'"
                    @click="hq.doSearchOrderBy('joindayasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in hq.list" :key="i">
                <td>{{ irow.num }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'myPIMS-PersonalInfoList_tab1-idx',
                      params: { idx: irow.idx },
                    }"
                    target="_blank"
                    ><span class="btn-view"
                      >{{ irow.ename }}({{ irow.kname }})</span
                    ></router-link
                  >
                </td>
                <td>
                  <p class="line-clamp-1">{{ irow.position_name }}</p>
                </td>
                <td>
                  <p class="line-clamp-1">{{ irow.team_name }}</p>
                </td>
                <td>
                  <p class="line-clamp-1">{{ irow.office_name }}</p>
                </td>
                <td>{{ irow.sector_name }}</td>
                <td>
                  <p class="line-clamp-1">{{ irow.corporation_name }}</p>
                </td>
                <td>{{ irow.birth }}</td>
                <td>{{ irow.joindate }}</td>
              </tr>
              <tr v-if="hq.total == 0">
                <td colspan="9">재직자 정보가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <CarrotPaging
            :total="hq.total"
            :list_per_page="hq.rows"
            v-model="hq.page"
            @change="hq.doSearch"
          ></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from "vue";
import { useRouter } from "vue-router";
import axios from "@/plugins/axios.js";

import CarrotSector from "@/components/common/CarrotSector.vue";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";
import CarrotDept from "@/components/common/CarrotDept.vue";
import CarrotTeam from "@/components/common/CarrotTeam.vue";
import { useStore } from "vuex";
export default {
  layout: "myPIMS",

  components: {
    CarrotDatePicker,
    CarrotDept,
    CarrotTeam,
    CarrotSector,
  },
  setup() {
    const router = new useRouter();
    const store = useStore();

    const hq = reactive({
      isAuth: false,
      page: 1,
      rows: 300,
      sdate: "",
      edate: "",
      phone: "",
      hqname: "",
      state: "DUTY",
      idx_sector: 0,
      idx_office: 0,
      idx_team: 0,
      join_sort: "",
      orderby: "",

      analysis: [],
      list: [],
      total: 0,
      doSearchOffice: () => {
        hq.doSearch();
      },
      doSearchTeam: () => {
        hq.doSearch();
      },
      doSearchJoinday: () => {
        if (hq.orderby == "joindayasc") hq.orderby = "joindaydesc";
        else hq.orderby = "joindayasc";
        hq.doSearch();
      },
      doSearchOrderBy: (orderby) => {
        hq.orderby = orderby;
        hq.doSearch();
      },
      downExcel: () => {
        // 로컬 테스트 시 local.intranet.mxm.kr
        let url =
          "https://api.carrotians.net" +
          "/excel/incumbent_management_list" +
          "?sdate=" +
          hq.sdate +
          "&edate=" +
          hq.edate +
          "&phone=" +
          hq.phone +
          "&hqname=" +
          hq.hqname +
          "&office=" +
          hq.idx_office +
          "&team=" +
          hq.idx_team +
          "&state=" +
          hq.state +
          "&sector=" +
          hq.idx_sector;
        +"&orderby=" + hq.orderby;
        window.open(url, "_blank").focus();
      },

      viewHQ: (idx) => {
        router.push({
          name: "myPIMS-PersonalInfoList_tab1-idx",
          params: { idx: idx },
        });
      },

      doSearchTotal: () => {
        let params = {};

        axios
          .get("/rest/mypims/getHQAnalysis", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              hq.analysis = res.data;
            } else {
              console.info(res.data.err_msg);
            }
          });
      },

      doSearch: () => {
        let params = {
          page: hq.page,
          rows: hq.rows,
          sdate: hq.sdate,
          edate: hq.edate,
          phone: hq.phone,
          hqname: hq.hqname,
          idx_sector: hq.idx_sector,
          idx_office: hq.idx_office,
          idx_team: hq.idx_team,
          state: hq.state,
          join_sort: hq.join_sort,
          orderby: hq.orderby,
        };

        axios.get("/rest/mypims/getHQList", { params: params }).then((res) => {
          if (res.data.err == 0) {
            hq.list = res.data.list;
            hq.total = res.data.total;
          } else {
            console.info(res.data.err_msg);
          }
        });
      },
    });

    onMounted(() => {
      // Mounted
      // console.log(store.state.pd_auth, store.state.position );
      // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐
      if (store.state.position != "리드" && store.state.pd_auth == false ) {
        //if( store.state.pd_auth != true && store.state.position != '리드' ) {
        router.go(-1);
      
        return;
      }

      if (store.state.pd_auth == true) {
        hq.isAuth = true;
      }

      hq.doSearch();
      hq.doSearchTotal();
    });

    onUnmounted(() => {
      // UnMounted
    });

    return { hq };
  },
};
</script>

<style lang="scss" scoped>
.sub-wrap .con-wrap .table-col.table-auto {
  // table-layout: auto;
}
</style>
